import { faBox, faBoxesPacking, faBoxesStacked, faCheck, faDoorClosed, faHandHoldingHand, faHands, faHighlighter, faLayerGroup, faMoneyCheckDollar, faPuzzlePiece, faSlash, faTag, faTemperature0, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { useTranslation } from "react-i18next";


const Servicios = ()=> {

    const { t } = useTranslation();

    return(
        <div id="container">
                <div className="banner servicios">
                    <div className="banner_text">
                        <h2>{t('36')} <span>{t('37')}</span></h2>
                    </div>
                </div>
                <div className="content_servicios">
                    <div className="content_left">
                    <h1>{t('38')} <b>{t('39')}</b><br></br> <span>{t('40')}</span></h1>
                    <div className="clear"></div>
                    <ul>
                        <li><img src="/img/almacenaje.png" alt="Almacenaje"></img> <span>{t('41')}</span><div className="clear"></div></li>
                        <div className="clear"></div>
                        <p>{t('42')}</p>
                        <h2 style={{marginBottom:"20px"}}>{t('43')}:</h2>
                        <ul>
                            <li><FontAwesomeIcon icon={faWarehouse} /> {t('44')}</li>
                            <li><FontAwesomeIcon icon={faSlash} /> {t('45')}</li>
                            <li><FontAwesomeIcon icon={faTag} /> {t('46')}</li>
                            <li><FontAwesomeIcon icon={faBoxesPacking} /> {t('47')}</li>
                            <li><FontAwesomeIcon icon={faHandHoldingHand} /> {t('48')}</li>
                            <li><FontAwesomeIcon icon={faPuzzlePiece} /> {t('49')}</li>
                            <li><FontAwesomeIcon icon={faBoxesPacking} /> {t('50')}</li>
                            <li><FontAwesomeIcon icon={faHighlighter} /> {t('51')}</li>
                            <li><FontAwesomeIcon icon={faBox} /> {t('52')}</li>
                            <li><FontAwesomeIcon icon={faLayerGroup} /> {t('53')}</li>
                            <li><FontAwesomeIcon icon={faBoxesStacked} /> {t('54')}</li>
                            <li><FontAwesomeIcon icon={faHands} /> {t('55')}</li>
                            <li><FontAwesomeIcon icon={faDoorClosed} /> {t('56')}</li>
                            <li><FontAwesomeIcon icon={faMoneyCheckDollar} /> {t('57')}</li>
                        </ul>

                        <h2 style={{marginBottom:"20px"}}>{t('58')}:</h2>
                        <ul> 
                            <li><FontAwesomeIcon icon={faBox} /> {t('59')}</li>
                            <li><FontAwesomeIcon icon={faTemperature0} /> {t('60')}</li>
                            <li><FontAwesomeIcon icon={faTemperature0} /> {t('61')}</li>
                            <li><FontAwesomeIcon icon={faBox} /> {t('62')}</li>
                            <li><FontAwesomeIcon icon={faBox} /> {t('63')}</li>
                        </ul>
                        <h2>{t('64')}</h2>
<p><b>{t('65')}</b> {t('66')}</p>

<p><b>{t('67')}</b>  {t('68')}</p>

<p><b>{t('69')}</b> : {t('70')}</p>

<p><b>{t('71')}</b></p>

                        <div className="clear"></div>
                        <li><img src="/img/distribucion.png" alt="Distribución"></img> <span>{t('72')}</span><div className="clear"></div></li>
                        <div className="clear"></div>
                        <h2>{t('73')}</h2>
                        <p>{t('74')}<br></br>
{t('75')}<br></br><br></br>

{t('76')} <br></br><br></br>
{t('77')}<br></br>
{t('78')}</p>
                    </ul>
                    </div>
                    <div className="content_right">
                        <img style={{marginTop:"30px"}} src="/img/mapa.jpg" alt="servicios"></img>
                        <ul>
                      
                        <li><img src="/img/operaciones.png" alt="Operaciones aduaneras"></img> <span>{t('79')}</span><div className="clear"></div></li>
                        <div className="clear"></div>
                        <p>{t('80')}</p>
<p>{t('81')}</p>
<p>{t('82')}:</p>
    <ul>
        <li><FontAwesomeIcon icon={faCheck} /> {t('83')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('84')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('85')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('86')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('87')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('88')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('89')}</li>
        <li><FontAwesomeIcon icon={faCheck} /> {t('90')}</li>
    </ul> 
                    </ul>
                    <div className="lqb"><a href="https://lqb.com.uy/" target="_blank" rel="noreferrer">{t('91')}</a></div>
                    </div>
                    <div className="clear"></div>
                </div>
        </div>
    );
}

export default Servicios;