import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Inicio = ()=> {

    const { t } = useTranslation();
    
    useEffect(() => {
        var scroll = document.getElementById("scroll");
        if(scroll){
            window.addEventListener('scroll', function() {
                if(window.scrollY >= 30) {
                        scroll.classList.add("active");
                    }else{
                        scroll.classList.remove("active");
                }
                });
        }

    },[]);

    return(
        <Fragment>
        <div id="container">
            <div id="video">
                <div className="video_bg"> </div>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/u1QiT6UJlSk?playlist=u1QiT6UJlSk&rel=0&autoplay=1&loop=1&mute=1&controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                <div id="scroll">
                <div className="mouse"></div>
                <p>Scroll</p>
                </div>
                
            </div>
            <div id="servicios">
                <div className="titulo">
                <h2>{t('1')} <span>{t('2')}</span> {t('3')}</h2>
                </div>
                <div className="box">
                    <div className="box_content">
                        <img src="/img/icono-transporte.png" alt="Transporte de cargas"></img>
                        <h2>{t('4')}</h2>
                        <p>{t('5')}</p>
                        <Link to="/servicios">{t('6')}</Link>
                    </div>
                </div>
                <div className="box">
                    <div className="box_content">
                        <img src="/img/icono-almacenamiento.png" alt="Almacenaje de mercaderías"></img>
                        <h2>{t('7')}</h2>
                        <p>{t('8')}</p>
                        <Link to="/servicios">{t('6')}</Link>
                    </div>
                </div>
                <div className="box">
                    <div className="box_content">
                        <img src="/img/icono-logistica.png" alt="Servicios logísticos"></img>
                        <h2>{t('9')}</h2>
                        <p>{t('10')}</p>
                        <Link to="/servicios">{t('6')}</Link>
                    </div>
                </div>
                <div className="clear"></div>
                <Link className="ver_todos" to="/servicios">{t('11')}</Link>
            </div>
            <div id="nosotros" className="home">
                <div className="content_left" style={{ backgroundImage: `url("/img/nosotros.jpg")`}}>
                       
                </div>
                <div className="content_right">
                        <h2><span>{t('12')}</span>. {t('13')} <span>{t('14')}</span> {t('15')}</h2>
                        <p>{t('16')}<br></br><br></br>
{t('17')}<br></br><br></br>
{t('18')}
{t('19')}<br></br><br></br>
{t('20')}</p>
                </div>
            </div>
            <div id="zonafranca">
                <div className="content_left">
                    <h2>{t('21')} <span>{t('22')}</span></h2>
                    <p>{t('23')}</p>
                    <p>{t('117')}</p>
                    <p>{t('118')}</p>
                    <p>{t('24')} <a href="https://www.zonafrancapdlc.com/" target="_blank" rel="noreferrer">https://www.zonafrancapdlc.com/</a></p>
                </div>
                <div className="content_right">
                    <div className="video_bg"></div>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/_6YtGidzhdo?playlist=_6YtGidzhdo&rel=0&autoplay=1&loop=1&mute=1&controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                </div>
                <div className="clear"></div>
            </div>
        </div>
        </Fragment>
    );
}

export default Inicio;