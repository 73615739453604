import React, { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import Inicio from "./pages/Inicio";
import Nosotros from "./pages/Nosotros";
import Servicios from "./pages/Servicios";
import Contacto from "./pages/Contacto";
import Header from "./components/Header";
import Footer from "./components/Footer";


const App = ()=> {
  return (
    <Fragment>
      <Header />  
      <Routes>
        <Route index element={<Inicio />} />
        <Route path="nosotros" element={<Nosotros />} />
        <Route path="servicios" element={<Servicios />} />
        <Route path="contacto" element={<Contacto />} />
        <Route path="*"  />
      </Routes>
      <Footer />
      </Fragment>
  );
}

export default App;
