import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false, 
    },
    resources: {
      en: {
        translation: {
          1:'Solutions ',
          2:'Versatile ',
          3:'for all types of cargo',
          4:'Cargo transportation',
          5:'We coordinate the transportation of your goods to and from our Warehouse in Parque de las Ciencias using our network of associated carriers.',
          6:'SEE MORE',
          7:'Storage of merchandise',
          8:'Certik has 5000m3 of warehouses (with racks) to store any type of merchandise, even refrigerated.',
          9:'Logistics services',
          10:'We coordinate the entire logistics process of your supply chain, from packing to labeling of merchandise.',
          11:'See all services',
          12:'Quality is not only our standard ',
          13:'It is also an',
          14:'attitude',
          15:'instilled in our company.',
          16:'We are located in the Parque de las Ciencias Free Trade Zone (Route 101, km 23,500), CERTIK is located just 4.6 km (7´) from the Carrasco International Airport and 32 km from the Port of Montevideo.',
          17:'Our facilities are located in an area surrounded by green areas, which allows us to create a calm and relaxed environment to develop logistics operations.',
          18:'We have 24 hour surveillance, 7 days a week, to guarantee the protection and security of our',
          19:'clients assets.',
          20:'In addition, our facilities are equipped with fire prevention and theft security measures.',
          21:'Manage your global or regional business from',
          22:'Parque de las Ciencias, Uruguay.',
          23:'This stable country with great economic freedom offers the perfect setting to coordinate your operations in the world or with a focus on Latin America, one of the most dynamic and growing economies in the world.',
          117:'Uruguay has a high educational and cultural level, where its qualified workforce and admirable quality of life particularly stand out.',
          118:'Legal security, political & economic stability, excellent transportation routes, advanced telecommunications, international trade treaties, and even the absence of natural disasters, have made Uruguay become the preferential nexus of the main global companies to project towards the rest of Latin America and the world.',
          24:'Source:',
          25:'CONTACT',
          26:'Home / Contact',
          27:'Feel free to speak to our representative at any time, use our contact form on our website or one of our contact numbers.',
          28:'Address',
          29:'Ruta 101-Capitan Juan Antonio Artigas, 14000 Departamento de Canelones, Edificio M3D-2, Local M1-D, Parque de las Ciencias (Zona Franca)',
          30:'Phones',
          31:'Contact Form',
          32:'Name',
          33:'Subject',
          34:'Email',
          35:'SEND',
          36:'Our services',
          37:'Home / Our services',
          38:'We provide',
          39:'solutions',
          40:'for every need.',
          41:'Storage',
          42:'Our team of highly trained professionals with extensive experience in logistics is responsible for coordinating and managing all the handling and storage processes of your merchandise, providing you with peace of mind and security at each stage of the process.',
          43:'SERVICES',
          44:'Storage',
          45:'Division',
          46:'Labelled',
          47:'Packaging',
          48:'Picking',
          49:'Assemblies',
          50:'Repackaging',
          51:'Highlighted',
          52:'Assembly of kits',
          53:'Grouping',
          54:'Inventory control',
          55:'Handling',
          56:'Exclusive use area',
          57:'Financial services',
          58:'Type of loads we receive in our warehouse',
          59:'General cargo',
          60:'Controlled temperature',
          61:'Refrigerated load',
          62:'IMO Dangerous Cargo',
          63:'Among other',
          64:'Benefits of storing merchandise in CERTIK:',
          65:'Tax exemptions:',
          66:'Companies that operate in CERTIK, under the free zone regime, are exempt from paying taxes and free circulation of merchandise within the free territory, which allows them to reduce costs and increase profitability.',
          67:'Simplification of customs procedures:',
          68:'The legal regime of the Uruguayan free zone facilitates customs procedures and speeds up the process of importing and exporting products. Strategic location: The Parque de las Ciencias free zone has a strategic location near the Carrasco Airport and the Port of Montevideo, which facilitates the transportation and distribution of merchandise.',
          69:'Flexibility',
          70:'The Uruguayan free zone allows any type of product to be stored without geographical restrictions or time limits, which provides greater flexibility to companies. We offer numerous benefits to companies looking to store merchandise safely and at a lower cost. If you are looking for an efficient and cost-effective storage solution.',
          71:'We provide a comprehensive and efficient service that allows you to optimize your commercial operations and reduce costs in the storage and distribution process of your products.',
          72:'Distribution',
          73:'TRANSPORTATION OF GOODS: NATIONAL AND INTERNATIONAL',
          74:'From CERTIK within the Science Park Free Zone, we offer efficient and effective regional distribution for the merchandise stored in our warehouse.',
          75:'We have an extensive transportation and distribution network that allows us to reach any part of the region quickly and professionally.',
          76:'Our logistics team is highly trained in inventory management, transportation coordination and delivery of merchandise in a timely manner. ',
          77:'We offer distribution services both nationally and internationally, depending on the needs of our clients.',
          78:'We ensure that each shipment is treated with the utmost care, guaranteeing the safety and protection of the stored products.',
          79:'Customs operations',
          80:'If you require Dispatch service, CERTIK, through a customs transit operation, will arrange your products at our facilities.',
          81:'By having your products in our premises, it is possible to quickly nationalize them, ensuring the availability of the merchandise for customers and maximizing sales.',
          82:'Among the services we offer',
          83:'Management of customs operations',
          84:'Transit dispatches',
          85:'Partial shipments',
          86:'Dispatches for: import, export, temporary admission, among other regimes',
          87:'Financial services for your merchandise',
          88:'Issuance of insurance policies for merchandise',
          89:'Derived Certificates of Origin',
          90:'Comprehensive advice on Foreign Trade and Logistics',
          91:'More information',
          92:'ABOUT US',
          93:'Home / About Us',
          94:'Our values',
          95:'Professionalism',
          96:'Honesty',
          97:'Confidentiality',
          98:'Innovation',
          99:'Engagement with the client',
          100:'Clear quality and safety policy',
          101:'Specialized human team',
          102:'Our mission',
          103:'Provide comprehensive and efficient advice to our clients in order to increase their productivity and competitiveness, highlighting personalized and professional attention.',
          104:'Our vision',
          105:'Our vision is to position ourselves as a leader and reference in Uruguay, the region and the world, in everything related to customs management, international trade and logistics.',
          106:'We provide an efficient and high-quality service in the traffic of merchandise and international trade.',
          107:'NAVIGATION',
          108:'Home',
          109:'About us',
          110:'Services',
          111:'Contact',
          112:'CONTACT',
          113:'Address',
          114:'Ruta 101-Capitan Juan Antonio Artigas, 14000 Departamento de Canelones, Edificio M3D-2, Local M1-D, Parque de las Ciencias (Zona Franca)',
          115:'Phones',
          116:'Email',
          
        },
        
      },
      es: {
        translation: {
          1:'Soluciones',
          2:'versátiles',
          3:'para todo tipo de carga',
          4:'Transporte de cargas',
          5:'Coordinamos el transporte de sus bienes desde y hacia nuestro Warehouse en Parque de las Ciencias utilizando nuestra red transportistas asociados.',
          6:'VER MÁS',
          7:'Almacenaje de mercaderías',
          8:'Certik cuenta con 5000m3 de depósitos (con racks) para almacenar cualquier tipo de mercaderías, incluso refrigeradas.',
          9:'Servicios logísticos',
          10:'Coordinamos todo el proceso logístico de su cadena de suministro, desde packing hasta etiquetado de mercaderías.',
          11:'Ver todos los servicios',
          12:'La calidad no es solo nuestro estándar',
          13:'También es una',
          14:'actitud',
          15:'inculcada en nuestra compañía.',
          16:'Estamos situados en Zona Franca Parque de las Ciencias (Ruta 101, km 23,500), CERTIK se encuentra a solo 4,6 km (7´) del Aeropuerto Internacional de Carrasco y a 32 km del Puerto de Montevideo.',
          17:'Nuestras instalaciones están ubicadas en un área rodeada de áreas verdes, lo que nos permite crear un ambiente tranquilo y relajado para desarrollar las operaciones logísticas.',
          18:'Contamos con vigilancia 24 horas, los 7 días de la semana, para garantizar la protección y seguridad de',
          19:'los bienes de nuestros clientes.',
          20:'Además, nuestras instalaciones están equipadas con medidas de prevención de incendios y de seguridad contra robos.',
          21:'Maneje su negocio mundial o regional desde',
          22:'Parque de las ciencias, Uruguay.',
          23:'Este país estable y de gran libertad económica ofrece el escenario perfecto para coordinar sus operaciones en el mundo o con foco en América Latina, una de las economías más dinámicas y crecientes del mundo.',
          24:'Fuente:',
          25:'CONTACTO',
          26:'Inicio / Contacto',
          27:'No dude en hablar con nuestro representante en cualquier momento, utilice nuestro formulario de contacto en nuestro sitio web o uno de nuestros números de contacto.',
          28:'Dirección',
          29:'Ruta 101-Capitan Juan Antonio Artigas, 14000 Departamento de Canelones, Edificio M3D-2, Local M1-D, Parque de las Ciencias (Zona Franca)',
          30:'Teléfonos',
          31:'Formulario de contacto',
          32:'Nombre',
          33:'Asunto',
          34:'Email',
          35:'ENVIAR',
          36:'Nuestros servicios',
          37:'Inicio / Nuestros servicios',
          38:'Brindamos',
          39:'soluciones',
          40:'para cada necesidad.',
          41:'Almacenaje',
          42:'Nuestro equipo de profesionales altamente capacitados y con amplia experiencia en logística, se encarga de coordinar y gestionar todos los procesos de manejo y almacenamiento de sus mercancías, brindándole tranquilidad y seguridad en cada etapa del proceso.',
          43:'SERVICIOS',
          44:'Almacenaje',
          45:'Fraccionamiento',
          46:'Etiquetado',
          47:'Empaquetado',
          48:'Picking',
          49:'Ensamblajes',
          50:'Re envasado',
          51:'Remarcado',
          52:'Armado de kits',
          53:'Agrupamiento',
          54:'Control de inventarios',
          55:'Manipuleo',
          56:'Área de uso exclusivo',
          57:'Servicios financieros',
          58:'Tipo de cargas que recibimos en nuestro deposito',
          59:'Carga general',
          60:'Temperatura controlada',
          61:'Carga refrigerada',
          62:'Carga Peligrosa IMO',
          63:'Entre otras',
          64:'Beneficios de almacenar la mercadería en CERTIK:',
          65:'Exenciones fiscales:',
          66:'Las empresas que operan en CERTIK, bajo régimen de zona franca están exentas del pago de impuestos y libre circulación de mercaderías dentro del territorio franco, lo que permite reducir costos y aumentar la rentabilidad.',
          67:'Simplificación de trámites aduaneros:',
          68:'El régimen jurídico de la zona franca uruguaya facilita los trámites aduaneros y agiliza el proceso de importación y exportación de productos. Ubicación estratégica: La zona franca Parque de las Ciencias cuenta con una ubicación estratégica cerca del Aeropuerto de Carrasco y Puerto de Montevideo, lo que facilita el transporte y distribución de mercaderías.',
          69:'Flexibilidad',
          70:'La zona franca uruguaya permite almacenar cualquier tipo de producto sin restricciones geográficas o límites de tiempo, lo que brinda mayor flexibilidad a las empresas. Ofrecemos numerosos beneficios a las empresas que buscan almacenar mercadería de forma segura y con menor costo. Si estás buscando una solución de almacenamiento eficiente y rentable.',
          71:'Brindamos un servicio integral y eficiente que le permite optimizar sus operaciones comerciales y reducir costos en el proceso de almacenamiento y distribución de sus productos.',
          72:'Distribución',
          73:'TRANSPORTE DE MERCADERÍAS: NACIONAL E INTERNACIONAL',
          74:'Desde CERTIK dentro de la Zona Franca Parque de la Ciencias, ofrecemos una distribución regional eficiente y efectiva para las mercaderías almacenadas en nuestro depósito.',
          75:'Contamos con una amplia red de transporte y distribución que nos permite llegar a cualquier parte de la región con rapidez y profesionalismo.',
          76:'Nuestro equipo logístico, está altamente capacitado en la gestión de inventarios, la coordinación del transporte y la entrega de mercaderías en tiempo y forma.',
          77:'Ofrecemos servicios de distribución tanto a nivel nacional como internacional, dependiendo de las necesidades de nuestros clientes.',
          78:'Nos aseguramos de que cada envío sea tratado con el máximo cuidado, garantizando la seguridad y protección de los productos almacenados.',
          79:'Operaciones aduaneras',
          80:'En caso de requerir servicio de Despacho, CERTIK mediante una operación aduanera de tránsito, dispondrá sus productos en nuestras instalaciones.',
          81:'Al disponer de sus productos en nuestro recinto, es posible, hacer una rápida nacionalización de los mismos, asegurando la disponibilidad de la mercadería para los clientes y maximizando las ventas.',
          82:'Dentro de los servicios que ofrecemos',
          83:'Gestión de las operaciones aduaneras',
          84:'Despachos de tránsito',
          85:'Despachos parciales',
          86:'Despachos de: importación, exportación, admisión temporaria, entre otros regímenes',
          87:'Servicios financieros para sus mercaderías',
          88:'Emisión de pólizas de seguro a las mercaderías',
          89:'Certificados de Origen derivado',
          90:'Asesoramiento integral en Comercio Exterior y Logística',
          91:'Más Información',
          92:'NOSOTROS',
          93:'Inicio / Nosotros',
          94:'Nuestros Valores',
          95:'Profesionalismo',
          96:'Honestidad',
          97:'Confidencialidad',
          98:'Innovación',
          99:'Compromiso con el cliente',
          100:'Clara política de calidad y seguridad',
          101:'Equipo humano especializado',
          102:'Nuestra Misión',
          103:'Brindar asesoramiento integral y eficiente a nuestros clientes a modo de aumentar su productividad y competitividad, destacando la atención personalizada y profesional.',
          104:'Nuestra Visión',
          105:'Nuestra visión es posicionarnos como líder y referente en el Uruguay, la región y el mundo, en todo lo relativo a la gestión aduanera, comercio internacional y logística.',
          106:'Prestamos un servicio eficiente y de alta calidad en el tráfico de mercaderías y comercio internacional',
          107:'NAVEGACIÓN',
          108:'Inicio',
          109:'Nosotros',
          110:'Servicios',
          111:'Contacto',
          112:'CONTACTO',
          113:'Dirección',
          114:'Ruta 101-Capitan Juan Antonio Artigas, 14000 Departamento de Canelones, Edificio M3D-2, Local M1-D, Parque de las Ciencias (Zona Franca)',
          115:'Teléfonos',
          116:'Email',
          117:'Uruguay cuenta con un alto nivel educativo y cultural, donde se destaca particularmente su calificada fuerza laboral y admirable calidad de vida.',
          118:'La seguridad jurídica, estabilidad política & económica, las excelentes vías de transporte, la avanzada telecomunicación, los tratados comerciales internacionales, y hasta la ausencia de catástrofes naturales, han hecho que Uruguay se convierta en el nexo preferencial de las principales empresas mundiales para proyectarse hacia el resto de América Latina y el mundo.'
        },
        
      }
    }
  });

export default i18n;