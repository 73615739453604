import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Header = () => {

    const { t } = useTranslation();

    return(
        <div id="header">
          <div id="header_content">
            <div id="logo"><img src="/img/logo.svg" alt="logo"></img></div>
        <nav>
          <ul>
            <li><Link to="/">{t('108')}</Link></li>
            <li><Link to="/nosotros">{t('109')}</Link></li>
            <li><Link to="/servicios">{t('110')}</Link></li>
            <li><Link to="/contacto">{t('111')}</Link></li>
          </ul>
        </nav>
        </div>
      </div>
    );
}

export default Header;
