import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { useTranslation } from "react-i18next";

const Contacto = ()=> {

    const { t } = useTranslation();

    return(
        <div id="container">
            <div className="banner nosotros">
                <div className="banner_text">
                <h2>{t('25')} <span>{t('26')}</span></h2>
                </div>
            </div>
            <div className="content_contacto">
                    <div className="content_left">
                       <h2>{t('27')}</h2>
                       <ul>
                            <li><FontAwesomeIcon icon={faLocationDot} /> <b>{t('28')}:</b> {t('29')}</li>
                            <li><FontAwesomeIcon icon={faPhone} /> <b>{t('30')}:</b> (+598) 94 078 327 // (+598) 94 174 911</li>
                            <li><FontAwesomeIcon icon={faEnvelope} /> <b>Email:</b> comercial@certik.com.uy</li>
                       </ul>
                    </div>
                    <div className="content_right">
                        <div className="right_content">
                            <h2>{t('31')} </h2>
                            <form id="contact_form">
                                <input type="text" name="nombre"  tabIndex="1" placeholder={t('31')} />
                                <input type="text" name="asunto"  tabIndex="2" placeholder={t('32')} />
                                <input type="email" name="email"  tabIndex="3" placeholder={t('33')} />
                                <textarea name="mensaje" placeholder="Mensaje" tabIndex="4"></textarea>
                                <input type="submit" value={t('35')} />
                            </form>
                        </div>
                    </div>
                    <div className="clear"></div>
            </div>
            <div id="map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d839444.7851947536!2d-57.875666418921064!3d-34.728347762172156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x959f89b49537a70b%3A0x302b16ac3e60cf06!2sParque%20de%20las%20Ciencias%20(Entrada%20%2F%20Entrance)!5e0!3m2!1ses-419!2suy!4v1681781645889!5m2!1ses-419!2suy" width="100%" height="450" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
}

export default Contacto;