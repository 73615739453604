import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {

    const { t } = useTranslation();

    return(
        <Fragment>
        <div id="footer">
            <div id="footer_container">
                <div className="box">
                    <div id="logo_footer"><img src="/img/logo.svg" alt="logo footer"></img></div>
                    <div id="iconos"><img src="/img/iconos-footer-certik.png" alt="Iconos footer"></img></div>
                </div>
                <div className="box">
                    <h2>{t('107')}</h2>
                    <ul>
                        <li><Link to="/">{t('108')}</Link></li>
                        <li><Link to="/nosotros">{t('109')}</Link></li>
                        <li><Link to="/servicios">{t('110')}</Link></li>
                        <li><Link to="/contacto">{t('111')}</Link></li>
                    </ul>
                </div>
                <div className="box">
                    <h2>{t('112')}</h2>
                    <p><b>{t('113')}:</b>{t('114')}</p>
                    <p><b>{t('115')}:</b> (+598) 94 078 327<br></br>(+598) 94 174 911</p>
                    <p><b>{t('116')}:</b> comercial@certik.uy</p>
                </div>
                <div className="clear"></div>
            </div>
        </div>
        <div id="copy">Copyright © 2024 Certik. All rights reserved.</div>
        </Fragment>
    );
}

export default Footer;