import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { useTranslation } from "react-i18next";

const Nosotros = ()=> {

    const { t } = useTranslation();

    return(
        <div id="container">
        <div className="banner nosotros">
            <div className="banner_text">
                <h2>{t('92')} <span>{t('93')}</span></h2>
            </div>
        </div>
        <div className="content_nosotros">
           <div className="box">
                <div className="box_content">
                    <h2>{t('101')}</h2>
                    <ul>
                        <li><FontAwesomeIcon icon={faCheck} />{t('94')}</li>
                        <li><FontAwesomeIcon icon={faCheck} />{t('95')}</li>
                        <li><FontAwesomeIcon icon={faCheck} />{t('96')}</li>
                        <li><FontAwesomeIcon icon={faCheck} />{t('97')}</li>
                        <li><FontAwesomeIcon icon={faCheck} />{t('98')}</li>
                        <li><FontAwesomeIcon icon={faCheck} />{t('99')}</li>
                        <li><FontAwesomeIcon icon={faCheck} />{t('100')}</li>
                    </ul>
                </div>
            </div>
           <div className="box">
                <div className="box_content">
                    <h2>{t('102')}</h2>
                    <p className="vym">{t('103')}</p>
                </div>
           </div>
           <div className="box">
                <div className="box_content">
                    <h2>{t('104')}</h2>
                    <p className="vym">{t('105')}</p>
                </div>
           </div>
            <div className="clear"></div>
        </div>
        <div id="nosotros">
                <div className="content_left" style={{ backgroundImage: `url("/img/nosotrosimg.jpg")` }}>
                       
                </div>
                <div className="content_right">
                        <h2>{t('106')}</h2>
                </div>
            </div>
</div>
    );
}

export default Nosotros; 